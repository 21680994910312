<template>
  <div>
    <h2 class="mb-4">All Reviews</h2>
    <div class="d-flex align-center mb-4" style="gap: 8px">
      <v-text-field
        class="mt-0"
        hide-details
        background-color="white"
        prepend-inner-icon="mdi-magnify"
        single-line
        v-model="search"
        placeholder="Search..."
      ></v-text-field>
      <v-btn v-if="selected.length" color="error" depressed @click="deleteReviews"
        ><i class="ri-delete-bin-6-line ri-lg mr-2" style="font-weight: 400;"></i> Delete
        {{ selected.length }} selected</v-btn
      >
    </div>
    <ReviewTable :search="search" :type="'product'" :productId="productId" v-on:inputChange="updateSelected" />
  </div>
</template>

<script>
import ReviewTable from './components/ReviewTable.vue';

export default {
  components: {
    ReviewTable,
  },
  data() {
    let query = this.$route.query;
    return {
      productId: query.product_id,
      search: '',
      selected: [],
    };
  },
  methods: {
    async deleteReviews() {
      if (!this.selected.length) return;
      this.$store
        .dispatch('deleteProductReviews', {
          ids: this.selected.join(','),
          productId: this.productId,
          type: 'product',
        })
        .then(() => {
          console.log('deleted');
        })
        .catch(error => {
          console.log(error);
        });
    },
    publish() {
      console.log('publish');
    },
    unpublish() {
      console.log('unpublish');
    },
    updateSelected(selected) {
      this.selected = selected;
    },
  },
  watch: {
    selected: function(old, current) {
      console.log(old, current);
    },
  },
};
</script>

<style></style>
